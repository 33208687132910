/**
 * @Author: Ajay
 * @Date:   08-Mar-2018 12:18 +05:30
 * @Project: Ezyprocure
 * @Filename: util.service.js
 * @Last modified by:   Atul
 * @Last modified time: 07-09-2023 
 */


(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('UtilService', UtilService);

  UtilService.$inject = ['$http', '$q', 'toastr', 'fCsv', 'TIME_CONSTANTS'];

  function UtilService($http, $q, toastr, fCsv, TIME_CONSTANTS) {

    var UtilService = {
      padLeadingZero: padLeadingZero,
      swapArrayValueToEnd: swapArrayValueToEnd,
      generateDays: generateDays,
      csvToJson: csvToJson,
      getTimeAbbr12: getTimeAbbr12,
      isValidDateRange : isValidDateRange,
      removeItemOnce:removeItemOnce,
      addItemOnce:addItemOnce,
      validateDateRange:validateDateRange,
      areSpecificFiltersFilled:areSpecificFiltersFilled,
      checkDateRangeExists:checkDateRangeExists,
      checkDateRangeValidity:checkDateRangeValidity,
      checkDuplicateItem:checkDuplicateItem,
      validateMOQ:validateMOQ
    };
    return UtilService;

    /**
   * [padLeadingZero Show a leading zero if a number is less than 10]
   * @param  {[type]} str [String]
   * @param  {[type]} max [max]
   * @return {[type]}     [Converted string number array]
   */
    function padLeadingZero(str, max) {
      return str.length < max ? padLeadingZero("0" + str, max) : str;
    }

    /**
     * [swapArrayValueToEnd swapArrayValueToEnd]
     * @param  {[Array]} stringArray [stringArray]
     * @param  {[String]} stringValue [stringValues]
     * @return {[Array]}             [swappedArray]
     */
    function swapArrayValueToEnd(stringArray, stringValue) {
      for (var index in stringArray) {
        stringArray[index] === stringValue ? stringArray.push(stringArray.splice(index, 1)[0]) : 0;
      }
      return stringArray;
    }

    /**
     * [generateDays Generated date array from date range]
     * @param  {[Date]} startDate [startDate]
     * @param  {[Date]} endDate   [endDate]
     * @return {[Array]}           [Generated Days]
     */
    function generateDays(startDate, endDate) {
      var startDay = moment(startDate).date();
      var endDay = moment(endDate).date();
      var dayArray = _.range(startDay, endDay + 1, 1);
      var dayStringArray = dayArray.map(String);
      for (var i = 0; i < dayStringArray.length; i++) {
        dayStringArray[i] = this.padLeadingZero(dayStringArray[i], 2);
      }
      dayStringArray.push("Total");
      return dayStringArray;
    }

    /**
     * [csvToJson Convert CSV to JSON]
     * @param  {[String]} csvFilePath [csvFilePath]
     * @return {[JSON]}           [JSOn Object]
     */
    function csvToJson(csvFilePath) {
      var jsonString, jsonObj;
      var deferred = $q.defer();
      $http({
        url: csvFilePath,
        method: "GET"
      }).then(function(resp) {
        jsonString = fCsv.toJson(resp.data);
        jsonObj = JSON.parse(jsonString.replace(/\\n|\\r/g, ''));
        deferred.resolve(jsonObj);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getTimeAbbr12(value) {
      var timeConstants = angular.copy(TIME_CONSTANTS.timeConstants);
      var timeObj = _.find(timeConstants, function(time) { return time.value === value; });
      return timeObj.abbr12;
    }

    /**
     * [isValidDateRange Checks if the date range is valid or not - Provided from or toDate can be null]
     * @param {Date} fromDate 
     * @param {Date} toDate 
     */
    function isValidDateRange(fromDate, toDate){
      var isValidRange = true;
      if(fromDate && toDate){
        if(fromDate.getTime() === toDate.getTime()){
          isValidRange = true;
        }
        else if(fromDate < toDate){
          isValidRange = true;
        }
        else if(fromDate > toDate){
          isValidRange = false;
        }
      }
      return isValidRange;
    }

    function removeItemOnce(arr, value) {
      if(arr.length>0){
        var index = arr.indexOf(value);
        if (index > -1) {
          arr.splice(index, 1);
        }
      }
      return arr;
    }
    function addItemOnce(arr,value){
      const elementExists = arr.includes(value);
      if (!elementExists) {arr.push(value);}
      return arr;
    }
 
    function validateDateRange(fromDate, toDate, maxDays, title, require) {
      var differenceMs, differenceDays;
    
      if (require && (toDate === null || fromDate === null)) {
        toastr.error('Please select a date range!', title);
        return false;
      }
    
      if ((fromDate !== null && fromDate !== undefined) && (toDate !== null && toDate !== undefined)) {
        differenceMs = new Date(toDate) - new Date(fromDate);
        differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
    
        if (differenceDays > maxDays) {
          toastr.error('Date range should not be more than one year!', title);
          return false;
        }
      }
    
      return true;
    }
    
    function checkDateRangeValidity(fromDate, toDate, maxDays, title, dateFilterSelected) {
      var differenceMs, differenceDays;
    
      if(!dateFilterSelected){
        return false;
      }
    
      if ((fromDate !== null && fromDate !== undefined) && (toDate !== null && toDate !== undefined)) {
        differenceMs = new Date(toDate) - new Date(fromDate);
        differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
    
        if (differenceDays > maxDays) {
          toastr.error('Date range should not be more than one year!', title);
          return false;
        }
      }
    
      return true;
    }

    function checkDateRangeExists(fromDate, toDate, filterAvailable) {
      if(!filterAvailable){
        if ((toDate === null ||  toDate === undefined)||(fromDate === null||fromDate === undefined)) {
          return false;
        }
      }
      return true;
    }

    function areSpecificFiltersFilled(filtersToCheck,filter) {
      for (var i = 0; i < filtersToCheck.length; i++) {
        var filterKey = filtersToCheck[i];
        if (filter.hasOwnProperty(filterKey) && filter[filterKey]) {
          if (Array.isArray(filter[filterKey]) && filter[filterKey].length > 0) {
            return true; // At least one specific filter value is filled
          } else if (!Array.isArray(filter[filterKey])) {
            return true; // Value exists and is not an empty array
          }
        }
      }
      return false; // No specific filter value is filled
    }  
function checkDuplicateItem(poResponse, response,type)
{
var processOrders = poResponse;
  var existingItemCodes = _.map(processOrders, function(order) {
    if(type==1)
    return order.items.itemCode;
  else
  return order.itemCode;
  });
  function filterNewItems(existingItemCodes, newItems) {
    return _.filter(newItems, function(item) {
      if (_.includes(existingItemCodes, item.itemCode)) {
        toastr.info('Item ' + item.itemCode + ' already exists and has been removed.');
        return false;
      }
      return true;
    });
  }
   var filteredItems = filterNewItems(existingItemCodes, response);
   return filteredItems;
}

 function validateMOQ (form, itemDTOObj, isWeightRequired) {
  const maxPrecision = isWeightRequired ? 3 : 2;
  const multiplierFactor = itemDTOObj.item.multiplierFactor;
  const moq = itemDTOObj.item.moq;

  // Validate multiplierFactor precision only if it's greater than 0
  if (multiplierFactor > 0) {
      const decimalPart = multiplierFactor.toString().split(".")[1];
      const isValidPrecision = !decimalPart || decimalPart.length <= maxPrecision;
      form.muliplierFactor.$setValidity("max-precision", isValidPrecision);

      // Scale up to integers to avoid floating-point precision issues
      const scaledMOQ = Math.round(moq * 1000000);
      const scaledMultiplierFactor = Math.round(multiplierFactor * 1000000);

      // Validate MOQ against multiplierFactor
      const isValidMOQ = scaledMOQ % scaledMultiplierFactor === 0;
      form.moq.$setTouched();
      form.moq.$setValidity("moq", isValidMOQ);
  } else {
      // Mark multiplierFactor and MOQ as valid when multiplierFactor <= 0
      form.muliplierFactor.$setValidity("max-precision", true);
      form.moq.$setValidity("moq", true);
  }
};


  }
})();
